import { loadDetailedJob } from '@/client/redux/actions';
import {
  detailedJobSelector,
  industryExperienceSelector,
  userOnboardingFinishedVerifiedSelector,
} from '@/client/redux/selectors';
import { clearDetailedJob } from '@/client/redux/store/browse-job.slice';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUseJobProps } from './types';

const useJob = ({ jobId, button, closeDrawer }: IUseJobProps) => {
  const dispatch = useDispatch();
  const { detailedJob, isLoadingJobDetail } = useSelector(detailedJobSelector);
  const isOnboardingFinished = useSelector(userOnboardingFinishedVerifiedSelector);
  const industryExperiences = useSelector(industryExperienceSelector);

  useEffect(() => {
    dispatch(loadDetailedJob(jobId));
    return () => {
      dispatch(clearDetailedJob());
    };
  }, [dispatch, jobId]);

  const onBackClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const detailedJobPageHref = useMemo(() => `/jobs/${jobId}`, [jobId]);

  const jobInfoButton = useMemo(
    () =>
      button
        ? {
            ...button,
            ...(detailedJob?.isApplied || !isOnboardingFinished
              ? {
                  onClick: undefined,
                  label: detailedJob?.isApplied ? 'Applied' : 'Apply',
                  disabled: true,
                }
              : {}),
          }
        : undefined,
    [button, detailedJob?.isApplied, isOnboardingFinished],
  );

  const qualifications = useMemo(() => {
    const industryExperienceLabels =
      detailedJob?.industryExperience
        .map(
          (experienceId) =>
            industryExperiences.find((industryExperience) => industryExperience.id === experienceId)
              ?.label,
        )
        .filter(Boolean) ?? [];

    const aboutCandidate = detailedJob?.aboutCandidate ? `\n${detailedJob.aboutCandidate}` : '';

    return `${industryExperienceLabels.join(', ')}${aboutCandidate}`;
  }, [detailedJob?.aboutCandidate, detailedJob?.industryExperience, industryExperiences]);

  return {
    detailedJob,
    isLoadingJobDetail,
    detailedJobPageHref,
    jobInfoButton,
    qualifications,
    onBackClick,
  };
};

export default useJob;
