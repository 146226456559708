import MainJobInfo from '@/client/cards/DetailedJobCard/components/MainJobInfo';
import {
  CustomImage,
  LinkButton,
  LinkButtons,
  ListBlock,
  ReadMoreText,
  TextHtml,
  ViewLinksContainer,
} from '@/client/components';
import { candidateExperienceLevelLabels } from '@/client/constants';
import { RoleGates } from '@/client/gates';
import { availabilitiesOptions } from '@/client/options';
import { getLocationFromAddress } from '@/client/utils';
import { UserRole } from '@/enums';
import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { formatDistance } from 'date-fns';
import Image from 'next/image';
import DrawerHeader from '../DrawerHeader';
import useStyles from './styles';
import { IJobProps } from './types';
import useJob from './useJob';

export const Job = ({ jobId, closeDrawer, button }: IJobProps) => {
  const classes = useStyles();
  const {
    detailedJob,
    detailedJobPageHref,
    isLoadingJobDetail,
    jobInfoButton,
    qualifications,
    onBackClick,
  } = useJob({
    jobId,
    button,
    closeDrawer,
  });
  if (isLoadingJobDetail) {
    return <CircularProgress className={classes.loader} />;
  }
  if (!detailedJob) {
    return null;
  }

  return (
    <div className={classes.root}>
      <DrawerHeader backButton={onBackClick} openNewTabHref={detailedJobPageHref} />
      <div className={classes.mainJobInfo}>
        <CustomImage
          imageUri={detailedJob.companyInfo.logoUri}
          width={100}
          height={100}
          className={classes.companyLogo}
        />

        <MainJobInfo
          jobInfo={{
            date: detailedJob.publishedAt
              ? formatDistance(new Date(detailedJob.publishedAt), new Date(), {
                  addSuffix: true,
                })
              : '',
            jobTitle: detailedJob.title,
            match: detailedJob.isMatch,
            isRemote: detailedJob.isRemote,
            salaryFrom: detailedJob.salaryFrom,
            salaryTo: detailedJob.salaryTo,
            preferredAddress: detailedJob.preferredAddress,
          }}
          name={detailedJob.companyInfo.name}
          button={jobInfoButton}
          classes={{
            buttonClass: classes.button,
            infoTextClass: classes.infoTextClass,
            jobTitleClass: classes.jobTitleClass,
            boldTextClass: classes.boldTextClass,
            secondaryTextClass: classes.secondaryTextClass,
          }}
        />
      </div>
      <div className={classes.information}>
        <div>
          {detailedJob.aboutCompany && (
            <TextHtml text={detailedJob.aboutCompany} className={classes.infoText} />
          )}

          {detailedJob.aboutJob && (
            <>
              <Typography className={classes.paragraphTitle} variant="h3">
                Responsibilities
              </Typography>
              <TextHtml text={detailedJob.aboutJob} className={classes.infoText} />
            </>
          )}
          {detailedJob.perfectCandidates !== null && (
            <RoleGates
              compareRoles={[
                UserRole.MasterEmployer,
                UserRole.Employer,
                UserRole.Admin,
                UserRole.AdminEmployer,
              ]}
            >
              <ViewLinksContainer links={detailedJob.perfectCandidates} />
            </RoleGates>
          )}
          {!!qualifications && (
            <>
              <Typography className={classes.paragraphTitle} variant="h3">
                Desired Qualifications
              </Typography>
              <TextHtml text={qualifications} className={classes.infoText} />
            </>
          )}
        </div>

        <ListBlock
          className={clsx(classes.listBlock, {
            [classes.marginLeft]:
              detailedJob.aboutCompany || detailedJob.aboutJob || qualifications,
          })}
          listItemClass={classes.listBlockItem}
          list={[
            {
              label: detailedJob.jobFunction.name,
              caption: 'Job function',
              iconSrc: '/icons/lightblub-icon.svg',
            },
            {
              label:
                availabilitiesOptions.find((item) => item.value === detailedJob.employmentType)
                  ?.label || '',
              caption: 'Employment type',
              iconSrc: '/icons/calendar-icon.svg',
            },
            {
              label: candidateExperienceLevelLabels[detailedJob.experience],
              caption: 'Experience level',
              iconSrc: '/icons/experience-level-icon.svg',
            },
            {
              label: detailedJob.languages.join(', '),
              caption: 'Language requirements',
              iconSrc: '/icons/languages-icon.svg',
            },
          ]}
        />
      </div>
      <div className={classes.footer}>
        <Typography className={classes.descriptionBlockTitle} variant="h3">
          About the company
        </Typography>
        <div className={clsx(classes.flexBlock)}>
          <div className={classes.topWrapper}>
            <CustomImage
              imageUri={detailedJob.companyInfo.logoUri}
              className={classes.companyLogo}
            />

            <div>
              <div className={classes.flexBlock}>
                <Typography className={classes.companyName}>
                  {detailedJob.companyInfo.name}
                </Typography>

                {detailedJob.companyInfo.website && (
                  <LinkButton
                    href={detailedJob.companyInfo.website}
                    buttonType={LinkButtons.Base}
                    className={classes.websiteNearCompanyName}
                  >
                    <Image src="/icons/website-link-icon.svg" width={20} height={20} />
                  </LinkButton>
                )}
              </div>
              {detailedJob.companyInfo.address && (
                <div className={classes.locationBlock}>
                  <div className={classes.locationIcon}>
                    <Image src="/icons/location-icon.svg" width={24} height={24} />
                  </div>
                  <Typography className={clsx(classes.locationText, classes.fontStyles)}>
                    {getLocationFromAddress(detailedJob.companyInfo.address)}
                  </Typography>
                </div>
              )}
            </div>
          </div>

          {detailedJob.companyInfo.description && (
            <ReadMoreText
              className={classes.aboutBlock}
              textClass={classes.aboutText}
              text={detailedJob.companyInfo.description}
              hasReadMoreButton
            />
          )}
        </div>
      </div>
    </div>
  );
};
