import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
    flexGrow: 1,
    padding: theme.spacing(0, 20),
  },

  mainJobInfo: {
    marginTop: 50,
    display: 'flex',
  },

  companyLogo: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: theme.spacing(46),
    minWidth: theme.spacing(46),
    height: theme.spacing(46),
    marginRight: theme.spacing(6),
  },

  paragraphTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22.5px',
  },
  infoText: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.0016rem',
    whiteSpace: 'pre-wrap',
  },
  information: {
    display: 'flex',
    flexGrow: 1,
    marginTop: theme.spacing(17),
  },
  marginLeft: {
    marginLeft: theme.spacing(20),
  },
  listBlock: {
    minWidth: 320,
    width: '100%',
    marginTop: `${theme.spacing(0)} !important`,
    flexDirection: 'column',
    height: 'fit-content',
    padding: theme.spacing(12.5),
  },
  listBlockItem: {
    marginBottom: theme.spacing(15),
    width: '100% !important',
    maxWidth: '100% !important',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  descriptionBlockTitle: {
    marginBottom: theme.spacing(10),
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22.5px',
  },
  footer: {
    backgroundColor: '#F6F8FA',
    margin: theme.spacing(0, -20),
    marginTop: theme.spacing(15),
    padding: theme.spacing(15, 20, 20, 25),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 20, 21, 25),
    },
  },
  infoTextClass: {
    '& .MuiTypography-root': {
      fontSize: '18px',
      lineHeight: '30px',
      letterSpacing: '0.02em',
    },
  },
  jobTitleClass: {
    fontSize: '22px',
    lineHeight: '30px',
    fontWeight: 700,
    letterSpacing: '0.02em',
  },
  boldTextClass: {
    fontSize: '24px',
    lineHeight: '18px',
    '&:first-of-type': {
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(8),
      },
    },
    '& $secondaryTextClass': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        lineHeight: '18px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0),
    },
  },
  secondaryTextClass: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
  },

  flexBlock: {
    display: 'flex',
  },
  topWrapper: {
    display: 'flex',
    marginTop: theme.spacing(10),
  },

  companyName: {
    color: theme.palette.blackMain,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '30px',
  },

  locationBlock: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  locationText: {
    color: theme.palette.blackMain,
    margin: 'auto 0',
    marginLeft: theme.spacing(3),
  },
  locationIcon: {
    width: 24,
    height: 24,
  },
  employesText: {
    marginTop: theme.spacing(4),
    color: theme.palette.secondaryBlack,
  },
  websiteNearCompanyName: {
    padding: 0,
    margin: 'auto',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(50),
    height: 20,
    width: 20,
  },
  aboutText: {
    wordBreak: 'break-word',
    fontSize: '16px',
    lineHeight: '25px',
  },
  aboutBlock: {
    flex: '1',
    marginLeft: theme.spacing(49),
  },
  fontStyles: {
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.02rem',
  },
  backBtn: {
    display: 'none',
    padding: 0,
    width: 'auto',
    height: 'auto',
    margin: theme.spacing(12, 0),
    maxWidth: theme.spacing(36),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      position: 'absolute',
      left: theme.spacing(20),
      top: '50%',
      transform: 'translateY(-50%)',
      height: theme.spacing(12),
    },
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(12),
    },
  },
  button: {
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(8),
    },
  },
}));

export default useStyles;
